body {
  overflow-x: hidden;
  /* touch-action: none; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #414247 #20212c;
  /* Internet Explorer 10+ */
  -ms-overflow-style: none;
}
/* WebKit */
*::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
/* Track */
*::-webkit-scrollbar-track {
  background-color: #20212c;
}
*::-webkit-scrollbar-thumb {
  background-color: #414247;
}
/* Handle */
*::-webkit-scrollbar-thumb:hover {
  background-color: #86898b;
}
